/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useState } from 'react';

export interface CommonContextProps {
  reloadNotifications: boolean;
  setReloadNotifications: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommonContext = createContext<CommonContextProps | undefined>(
  undefined
);

interface CommonProviderProps {
  children: ReactNode;
}

export const CommonProvider = ({ children }: CommonProviderProps) => {
  const [reloadNotifications, setReloadNotifications] =
    useState<boolean>(false);

  return (
    <CommonContext.Provider
      value={{ reloadNotifications, setReloadNotifications }}
    >
      {children}
    </CommonContext.Provider>
  );
};

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilCube, UilPuzzlePiece } from '@iconscout/react-unicons';
import { PATHS } from 'core/constants/paths.constants';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'apps',
    icon: UilCube,
    pages: [
      {
        name: 'inicio',
        icon: 'home',
        path: PATHS.HOME,
        pathName: 'app-inicio',
        active: true
      },
      {
        name: 'estados',
        icon: 'activity',
        path: PATHS.STATES,
        pathName: 'app-estados',
        active: true
      },
      {
        name: 'issues',
        icon: 'inbox',
        path: PATHS.ISSUES,
        pathName: 'app-issues',
        active: true
      },
      {
        name: 'labels',
        icon: 'edit-3',
        path: PATHS.LABELS,
        pathName: 'app-labels',
        active: true
      },
      {
        name: 'usuarios',
        icon: 'user',
        path: PATHS.USERS,
        pathName: 'app-usuarios',
        active: true
      },
      {
        name: 'notificaciones',
        icon: 'bell',
        path: PATHS.NOTIFICATIONS,
        pathName: 'app-notificaciones',
        active: true
      },
      {
        name: 'calendario',
        icon: 'calendar',
        path: PATHS.CALENDAR,
        pathName: 'app-calendario',
        active: true
      },
      {
        name: 'Configuracón email',
        icon: 'mail',
        path: PATHS.CONFIGURACION_EMAIL,
        pathName: 'app-email',
        active: true
      }
    ]
  },
  {
    label: 'Configuración',
    active: true,
    icon: UilPuzzlePiece,
    megaMenu: true, // works for navbar top
    pages: [
      {
        name: 'logs',
        icon: 'git-branch',
        dropdownInside: true,
        active: true,
        // hasNew: true,
        pages: [
          {
            name: 'Envío email',
            path: PATHS.LOGS_EMAIL,
            pathName: 'app-send-email',
            active: true
          },
          {
            name: 'Inicio sesión',
            path: PATHS.LOGS_ACCESS,
            pathName: 'app-init-session',
            active: true
          }
        ]
      }
    ]
  }
];

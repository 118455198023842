/* eslint-disable @typescript-eslint/no-explicit-any */
import { AVATAR_IMG, PAGE_SIZE } from '../constants/common.constants';

export const getFormData = (inputs: HTMLInputElement[]) => {
  let obj = {};
  (inputs ?? [])?.forEach(input => {
    const { id, value } = input ?? {};
    if (!id) return;
    obj = {
      ...(obj ?? {}),
      [id]: value
    };
  });
  return obj;
};

export const patchForm = <T>(inputs: HTMLInputElement[], obj: T) => {
  (inputs ?? [])?.forEach(input => {
    const { id } = input ?? {};
    if (!id) return;
    input.value = (obj as any)?.[id] ?? '';
  });
};

export const formIsDisabled = (
  inputs: HTMLInputElement[],
  disabled: boolean = false
) => {
  (inputs ?? [])?.forEach(input => {
    const { id } = input ?? {};
    if (!id) return;
    if (disabled) input.setAttribute('disabled', 'true');
    else input.removeAttribute('disabled');
  });
};

export const editFormUrl = (url: string, id: number) => {
  const path = ((url ?? '')?.split('/') ?? [])?.slice(0, -1)?.join('/');
  return `${path}/${id}`;
};

export const tableSlice = <T>(
  tableData: T[] = [],
  page: number = 1,
  perPage: number = PAGE_SIZE
): T[] => {
  return (tableData ?? [])?.slice((page - 1) * perPage, page * perPage);
};

export const getTotalPages = <T>(
  tableData: T[] = [],
  perPage: number = PAGE_SIZE
): number => {
  return Math.ceil(tableData?.length / perPage) || 1;
};

export const sortAscItems = <T>(data: T[], field: string): T[] => {
  return [...(data ?? [])]?.sort((a: any, b: any) => {
    if (a?.[field] > b?.[field]) return -1;
    else if (a?.[field] < b?.[field]) return 1;
    return 0;
  });
};

export const sortDescItems = <T>(data: T[], field: string): T[] => {
  return [...(data ?? [])]?.sort((a: any, b: any) => {
    if (a?.[field] > b?.[field]) return 1;
    else if (a?.[field] < b?.[field]) return -1;
    return 0;
  });
};

export const sliceText = (text: string, size: number = 30) => {
  return text?.length > size ? text?.slice(0, size) + '...' : text ?? '';
};

export const creteFile = async (
  url: string,
  filename: string,
  mimeType?: string
) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
};

export const errorImage = (event: any): void => {
  event.target.src = AVATAR_IMG;
};

export const escapeHTML = (text: string): string => {
  return text ? text?.replace(/</g, '&lt;').replace(/>/g, '&gt;') : '';
};

export const replaceTextQuotation = (text: string): string => {
  return text ? text.replace(/"/g, '').replace(/'/g, '') : '';
};

export const converDateToSpanish = (englishDate: string): string => {
  const [time, hours] = englishDate?.split(' ') ?? [];
  const [yearSplitted, monthSplitted, daySplitted] = time?.split('-') ?? [];
  const day = daySplitted?.padStart(2, '0') ?? '';
  const month = monthSplitted?.padStart(2, '0') ?? '';
  const year = yearSplitted ?? '';
  if (hours && yearSplitted && monthSplitted && daySplitted)
    return `${day}-${month}-${year} ${hours ?? ''}`;
  return yearSplitted && monthSplitted && daySplitted
    ? `${day}-${month}-${year}`
    : englishDate ?? null;
};

// export const dateToSpanish = (date = ''): any => {
//   const [time, hour] = date?.split(' ') ?? [];
//   return time
//     ? [
//         ...(time
//           ?.toLocaleString('es-ES', {
//             year: 'numeric',
//             month: '2-digit',
//             day: '2-digit'
//           })
//           ?.split('-') ?? [])
//       ]
//         ?.reverse()
//         ?.join('-') + (hour ?? '')
//     : '';
// };

// pageTotal = Math.ceil(filterItems?.length / perPage) || 1;
// export const fillSelector = <T>(selector: HTMLInputElement, items: T[], id: number | string, label: string): void => {
//     selector.innerHTML = '';
//     selector.innerHTML += items?.length > 0 ? fillSelectorOptions(items, id, label) : '<option value="null"> --No hay datos-- </option>';
// }

// const fillSelectorOptions = <T>(items:T[], id: number | string, label: string) => {
//     return (items ?? [])?.map((item: any) => {
//         return `<option value="${item?.[id]}">${item?.[label] ?? '-'}</option>`
//     })?.join(',')?.replace(/,/g, '')
// }

// fillSelector(
//   document.querySelector("#categoria") as HTMLInputElement,
//   [{ id_categoria: "", nombre: "--Seleccione--" }, ...(categories ?? [])],
//   "id_categoria",
//   "nombre"
// );

/* eslint-disable @typescript-eslint/no-explicit-any */
export const callService = (url: string, options: any = {}): Promise<any> => {
  return fetch(url, {
    credentials: 'include',
    ...(options ?? {})
  })
    .then(r => r?.json())
    .then(r => {
      const error401 =
        Object.keys(r ?? {})?.[0]?.includes('401') &&
        (Object.values(r ?? {}) as any)?.[0]?.includes('Error 401');
      const error402 =
        Object.keys(r ?? {})?.[0]?.includes('402') &&
        (Object.values(r ?? {}) as any)?.[0]?.includes('Error 402');

      if (error401) throw 401;
      if (error402) throw 402;
      return r;
    })
    .catch(error => {
      throw error;
    });
};

/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOGGED_STORAGE_KEY } from 'core/constants/common.constants';
import { PATHS } from 'core/constants/paths.constants';
import { Credentials } from 'features/loign/models/credentials.model';
import { LoggedUser } from 'features/loign/models/logged-user.model';
import { LoginService } from 'features/loign/services/login.service';
import { createContext, ReactNode, useEffect, useState } from 'react';

export interface SessionContextProps {
  isLogged: LoggedUser | null;
  isLoadStorage: boolean;
  setIsBanned: React.Dispatch<React.SetStateAction<boolean>>;
  isBanned: boolean;
  login: (isCheck: boolean) => void;
  logout: () => void;
}

export const SessionContext = createContext<SessionContextProps | undefined>(
  undefined
);

interface SessionProviderProps {
  children: ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [isLogged, setIsLogged] = useState<LoggedUser | null>(null);
  const [isBanned, setIsBanned] = useState<boolean>(false);
  const [isLoadStorage, setIsLoadStorage] = useState<boolean>(true);

  useEffect(() => {
    checkIsLogged();
  }, []);

  const login = (isCheck: boolean = false) => {
    const loggedUser = LoginService.getLoggedUsers();
    setIsLogged(loggedUser ?? null);
    setInStorage(loggedUser);
    isCheck && setIsLoadStorage(false);
  };

  const checkIsLogged = () => {
    const storageItem = localStorage.getItem(LOGGED_STORAGE_KEY);
    const loggedUser = getInStorage(storageItem);

    if (Object.keys(loggedUser ?? {})?.length) {
      const { id, type, ...credentials } = (loggedUser ?? {}) as LoggedUser;
      refreshLogin(credentials as Credentials);
      return;
    } else {
      checkIsBanned();
    }
    // setIsLoadStorage(false);
  };

  const refreshLogin = async (credentials: Credentials) => {
    try {
      await LoginService.login({ ...(credentials ?? {}), isRefresh: true });
      login(true);
    } catch (error) {
      localStorage.removeItem(LOGGED_STORAGE_KEY);
      setIsLogged(null);
      setIsBanned(error === 500);
      window.location.href = PATHS.LOGIN;
    } finally {
      setIsLoadStorage(false);
    }
  };

  const checkIsBanned = async () => {
    const response = await LoginService.checkBanned().catch(() => false);

    if (response) {
      localStorage.removeItem(LOGGED_STORAGE_KEY);
      setIsLogged(null);
      setIsBanned(response);
    }

    setIsLoadStorage(false);
  };

  const logout = async () => {
    await LoginService.logout();
    localStorage.removeItem(LOGGED_STORAGE_KEY);
    setIsLogged(null);
  };

  const setInStorage = (loggedUser: LoggedUser | null): void => {
    try {
      localStorage.setItem(
        LOGGED_STORAGE_KEY,
        btoa(JSON.stringify(loggedUser))
      );
    } catch (error) {}
  };

  const getInStorage = (storageItem: string | null): LoggedUser | null => {
    let loggedUser;
    try {
      loggedUser = storageItem ? JSON.parse(atob(storageItem)) : null;
    } catch (error) {
      loggedUser = null;
    }
    return loggedUser;
  };

  return (
    <SessionContext.Provider
      value={{ isLogged, isLoadStorage, setIsBanned, isBanned, login, logout }}
    >
      {children}
    </SessionContext.Provider>
  );
};

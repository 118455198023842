import { Navigate } from 'react-router-dom';
import { LoggedUser } from '../../features/loign/models/logged-user.model';
import { PATHS } from '../constants/paths.constants';

export const LoggedLoginGuard = ({
  children,
  isLogged
}: {
  children: JSX.Element;
  isLogged: LoggedUser | null;
}) => {
  return isLogged ? <Navigate to={PATHS.HOME} /> : children;
};

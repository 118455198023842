import { CommonProvider } from 'core/context/CommonProvider';
import AppProvider from 'providers/AppProvider';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppRouter } from 'Routes';
import { PrimeReactProvider } from 'primereact/api';
// PRIME NG CSS
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { SessionProvider } from 'core/context/SessionProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SessionProvider>
      <AppProvider>
        <CommonProvider>
          <SettingsPanelProvider>
            <ChatWidgetProvider>
              <BreakpointsProvider>
                <PrimeReactProvider>
                  <AppRouter />
                </PrimeReactProvider>
              </BreakpointsProvider>
            </ChatWidgetProvider>
          </SettingsPanelProvider>
        </CommonProvider>
      </AppProvider>
    </SessionProvider>
  </React.StrictMode>
);

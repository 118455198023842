/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import Scrollbar from 'components/base/Scrollbar';
import NotificationItem from 'components/common/NotificationItem';
import { PATHS } from 'core/constants/paths.constants';
import { Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NoData } from 'ui/no-data/NoData';

interface NotificationDropdownMenuProps<T> {
  className?: string;
  notifications?: T[];
  goToNotification?: (item: T) => void;
}

const NotificationDropdownMenu = <T,>({
  className,
  goToNotification,
  notifications
}: NotificationDropdownMenuProps<T>) => {
  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-dropdown-caret py-0 notification-dropdown-menu shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Header className="p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-body-emphasis mb-0">Notificaciones</h5>
          </div>
        </Card.Header>
        <Card.Body className="p-0" style={{ height: '27rem' }}>
          <Scrollbar>
            {!notifications?.length ? (
              <div className="pt-1 notification-card position-relative">
                <div className="d-flex align-items-center justify-content-between position-relative">
                  <NoData
                    message="No hay ninguna notificación"
                    color={'black'}
                  />
                </div>
              </div>
            ) : (
              (notifications ?? [])?.map((item, idx) => (
                <NotificationItem
                  notification={item}
                  type="dropdownItem"
                  className={classNames({
                    'border-bottom border-translucent':
                      idx !== notifications.length - 1
                  })}
                  goToNotification={goToNotification as any}
                  key={idx}
                />
              ))
            )}
          </Scrollbar>
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          <div className="my-2 text-center fw-bold fs-10 text-body-tertiary text-opactity-85">
            <Link to={PATHS.NOTIFICATIONS} className="fw-bolder">
              Historial de notificaciones
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default NotificationDropdownMenu;

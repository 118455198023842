import { Navigate } from 'react-router-dom';
import { PATHS } from '../constants/paths.constants';

export const BannedGuard = ({
  children,
  isBanned = false
}: {
  children: JSX.Element;
  isBanned: boolean;
}) => {
  return !isBanned ? children : <Navigate to={PATHS.ERROR_500} />;
};

import { Navigate } from 'react-router-dom';
import { LoggedUser } from '../../features/loign/models/logged-user.model';
import { PATHS } from '../constants/paths.constants';

export const LoggedGuard = ({
  children,
  isLogged
}: {
  children: JSX.Element;
  isLogged: LoggedUser | null;
}) => {
  return isLogged ? children : <Navigate to={PATHS.LOGIN} />;
};

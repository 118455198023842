export const PATHS = {
  LOGIN: '/_admin/login',
  ERROR_404: '/_admin/404',
  ERROR_500: '/_admin/500',
  HOME: '/_admin/inicio',
  STATES: '/_admin/estados',
  STATE_CREATE: '/_admin/estado/alta',
  STATE_UPDATE: '/_admin/estado/editar/:id',
  PRIORITIES: '/_admin/prioridades',
  PRIORITY_CREATE: '/_admin/prioridad/alta',
  PRIORITY_UPDATE: '/_admin/prioridad/editar/:id',
  ISSUES: '/_admin/issues',
  ISSUE_CREATE: '/_admin/issue/alta',
  ISSUE_UPDATE: '/_admin/issue/editar/:id',
  LABELS: '/_admin/labels',
  LABEL_CREATE: '/_admin/label/alta',
  LABEL_UPDATE: '/_admin/label/editar/:id',
  NOTIFICATIONS: '/_admin/notificaciones',
  NOTIFICATIONS_OPEN: (id: number) => `/_admin/notificaciones?id=${id}`,
  USERS: '/_admin/usuarios',
  USER_CREATE: '/_admin/usuario/alta',
  USER_UPDATE: '/_admin/usuario/editar/:id',
  CONFIGURACION_EMAIL: '/_admin/configuracion-email',
  LOGS_EMAIL: '/_admin/logs-envio-email',
  LOGS_ACCESS: '/_admin/logs-inicio-sesion',
  CALENDAR: '/_admin/calendarios'
};

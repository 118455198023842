export const PROJECT_NAME = 'pruebas-react';

const PROJECT_NAME_MAP = () => {
  const title = PROJECT_NAME?.replace(/-/g, ' ');
  const [firstLetter, ...rest] = title?.split('') ?? [];
  return [firstLetter?.toUpperCase(), ...rest]?.join('');
};

export const LABEL_PROJECT_NAME = PROJECT_NAME_MAP();

const IS_DEV = false;

export const BASE_URL_PRO = `https://pruebasreact.bitar.biz`;

export const BASE_URL_DEV = `http://localhost/${PROJECT_NAME}`;

export const BASE_URL = IS_DEV ? BASE_URL_DEV : BASE_URL_PRO;

export const LOGGED_STORAGE_KEY = btoa('app_key_logged_' + PROJECT_NAME);

export const PAGE_SIZE = 10;

export const DEFAULT_PAGE = 1;

export const VERSION = '1.0.0';

export const AVATAR_IMG = '/assets/img/avatarPerfil.png';

export const LOGO_SM = '/assets/img/logo.png';

export const LOGO_XL = '/assets/img/logo-bitar.png';

export const ADMIN_CREDENTIALS_REMEMBER = PROJECT_NAME + '_credentials_admin';

export const IMAGE_URL = (file: string, folder: string = 'perfiles') =>
  `${BASE_URL}/api/_permanente/${folder}/${file}`;

export const SECRET_KEY = '1324354657';

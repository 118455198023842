/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import ThemeToggler from 'components/common/ThemeToggler';
import { AVATAR_IMG, IMAGE_URL } from 'core/constants/common.constants';
import { PATHS } from 'core/constants/paths.constants';
import { CommonContext } from 'core/context/CommonProvider';
import FeatherIcon from 'feather-icons-react';
import { LoginService } from 'features/loign/services/login.service';
import { Notification } from 'features/notification/models/notification.models';
import { NotificationService } from 'features/notification/services/notification.service';
import { User } from 'features/user/models/user.models';
import { UserService } from 'features/user/services/user.service';
import { useAppContext } from 'providers/AppProvider';
import { useContext, useEffect, useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { Badge } from 'primereact/badge';

const NavItems = () => {
  const navigate = useNavigate();
  const [loggedUser, setLoggedUser] = useState<User | null>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  // CONTECTO DE AppProvider******************
  const context = useContext(CommonContext);
  const { reloadNotifications } = context as any;
  // CONTECTO DE AppProvider******************

  const {
    config: { navbarPosition }
  } = useAppContext();
  // const [openSearchModal, setOpenSearchModal] = useState(false);

  useEffect(() => {
    loadData();
  }, [reloadNotifications]);

  const loadData = async (): Promise<void> => {
    const response = LoginService.getLoggedUsers();
    const { id } = response ?? {};

    const [users, notifications] = await Promise.all([
      UserService.getById(+id!).catch(() => {}),
      NotificationService.getByLogged(true).catch(() => [])
    ]);

    setLoggedUser(users as User);
    changeNotification(notifications);
  };

  const goToNotification = async (
    notification: Notification
  ): Promise<void> => {
    const { id, leida: isRead } = notification ?? {};
    if (!isRead) {
      const notifications = await NotificationService.checkRead(id).catch(
        () => []
      );
      changeNotification(notifications);
    }

    navigate(PATHS.NOTIFICATIONS_OPEN(id));
  };

  const changeNotification = (notifications: Notification[]): void => {
    const notReadNotifications = (notifications ?? [])?.filter(
      item => !item?.leida
    );
    setTotalNotifications(notReadNotifications?.length);
    setNotifications(notifications);
  };

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>
      <Nav.Item
        className={classNames({
          'd-lg-none':
            navbarPosition === 'vertical' || navbarPosition === 'dual'
        })}
      >
        {/* <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
        </Nav.Link> */}
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link h-100"
            variant=""
            style={{ position: 'relative' }}
          >
            {totalNotifications ? (
              <Badge
                value={totalNotifications || 0}
                style={{
                  position: 'absolute',
                  top: '0px',
                  left: '20px'
                }}
                severity="danger"
              ></Badge>
            ) : (
              <></>
            )}
            <FeatherIcon icon="bell" size={25} />
          </Dropdown.Toggle>
          {/* NOTIFICACIONES  */}
          <NotificationDropdownMenu
            goToNotification={goToNotification}
            notifications={notifications}
          />
        </Dropdown>
      </Nav.Item>
      {/* <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link h-100"
            variant=""
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
            </svg>
          </Dropdown.Toggle>
          <NineDotMenu />
        </Dropdown>
      </Nav.Item> */}
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar
              src={loggedUser?.foto ? IMAGE_URL(loggedUser?.foto) : AVATAR_IMG}
              size="l"
            />
          </Dropdown.Toggle>
          <ProfileDropdownMenu
            loggedUser={loggedUser as User}
            image={loggedUser?.foto ? IMAGE_URL(loggedUser?.foto) : AVATAR_IMG}
          />
        </Dropdown>
      </Nav.Item>

      {/* <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default NavItems;

/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import { AVATAR_IMG } from 'core/constants/common.constants';
import { SessionContext } from 'core/context/SessionProvider';
import FeatherIcon from 'feather-icons-react';
import { User } from 'features/user/models/user.models';
import { useContext } from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';

const ProfileDropdownMenu = ({
  className,
  loggedUser,
  image = AVATAR_IMG
}: {
  className?: string;
  loggedUser?: User | null;
  image?: string | null;
}) => {
  const context = useContext(SessionContext);
  const { logout } = context as any;
  // const [navItems] = useState([
  //   {
  //     label: 'Profile',
  //     icon: 'user'
  //   },
  //   {
  //     label: 'Dashboard',
  //     icon: 'pie-chart'
  //   },
  //   {
  //     label: 'Posts & Activity',
  //     icon: 'lock'
  //   },
  //   {
  //     label: 'Settings & Privacy ',
  //     icon: 'settings'
  //   },
  //   {
  //     label: 'Help Center',
  //     icon: 'help-circle'
  //   },
  //   {
  //     label: 'Language',
  //     icon: 'globe'
  //   }
  // ]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={image ?? AVATAR_IMG} size="xl" />
            <h6 className="text-body-emphasis">
              {loggedUser?.user_nombre ?? '-'}
            </h6>
          </div>
          {/* <div className="mb-3 mx-3">
            <Form.Control
              type="text"
              placeholder="Update your status"
              size="sm"
            />
          </div> */}
          {/* <div style={{ height: '10rem' }} className="mt-2">
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href="#!" className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-body"
                      />
                      <span className="text-body-highlight">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div> */}
        </Card.Body>
        <Card.Footer className="p-0 border-top border-translucent">
          {/* <Nav className="nav flex-column my-3">
            <Nav.Item>
              <Nav.Link href="#!" className="px-3">
                <FeatherIcon
                  icon="user-plus"
                  size={16}
                  className="me-2 text-body"
                />
                <span>Add another account</span>
              </Nav.Link>
            </Nav.Item>
          </Nav> */}
          {/* <hr /> */}
          <div className="px-3 mt-2">
            <Button
              to="#!"
              onClick={logout}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Button>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            {/* <Link className="text-body-quaternary me-1" to="#!">
              Privacy policy
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-body-quaternary ms-1" to="#!">
              Cookies
            </Link> */}
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;

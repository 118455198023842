/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-explicit-any */
import App from 'App';
import { PATHS } from 'core/constants/paths.constants';
import { SessionContext } from 'core/context/SessionProvider';
import { BannedGuard } from 'core/guards/BannedGuard';
import { LoggedGuard } from 'core/guards/LoggedGuard';
import { LoggedLoginGuard } from 'core/guards/LoggedLoginGuard';
import MainLayout from 'layouts/MainLayout';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import { lazy, useContext } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider
} from 'react-router-dom';
import { Spinner } from 'ui/spinner/Spinner';

const Login = lazy(() =>
  import('./views/login/containers/Login').then(m => ({
    default: m.Login
  }))
);
const Home = lazy(() =>
  import('./views/home/containers/Home').then(m => ({
    default: m.Home
  }))
);
const StateList = lazy(() =>
  import('./views/state/containers/StateList').then(m => ({
    default: m.StateList
  }))
);
const StateForm = lazy(() =>
  import('./views/state/containers/StateForm').then(m => ({
    default: m.StateForm
  }))
);
const IssueList = lazy(() =>
  import('./views/issue/containers/IssueList').then(m => ({
    default: m.IssueList
  }))
);
const IssueForm = lazy(() =>
  import('./views/issue/containers/IssueForm').then(m => ({
    default: m.IssueForm
  }))
);
const LabelList = lazy(() =>
  import('./views/label/containers/LabelList').then(m => ({
    default: m.LabelList
  }))
);
const LabelForm = lazy(() =>
  import('./views/label/containers/LabelForm').then(m => ({
    default: m.LabelForm
  }))
);
const UserList = lazy(() =>
  import('./views/user/containers/UserList').then(m => ({
    default: m.UserList
  }))
);
const UserForm = lazy(() =>
  import('./views/user/containers/UserForm').then(m => ({
    default: m.UserForm
  }))
);
const NotificationList = lazy(() =>
  import('./views/notification/containers/NotificationList').then(m => ({
    default: m.NotificationList
  }))
);

const Calendar = lazy(() =>
  import('./views/calendar/containers/Calendar').then(m => ({
    default: m.Calendar
  }))
);

const EmailLogsList = lazy(() =>
  import('./views/email/containers/EmailLogsList').then(m => ({
    default: m.EmailLogsList
  }))
);

const EmailForm = lazy(() =>
  import('./views/email/containers/EmailForm').then(m => ({
    default: m.EmailForm
  }))
);

const AccessLogsList = lazy(() =>
  import('./views/login/containers/AccessLogsList').then(m => ({
    default: m.AccessLogsList
  }))
);

const Error404 = lazy(() =>
  import('./views/404/containers/Error404').then(m => ({
    default: m.Error404
  }))
);

const Error500 = lazy(() =>
  import('./views/500/containers/Error500').then(m => ({
    default: m.Error500
  }))
);

export const AppRouter = () => {
  const context = useContext(SessionContext);
  const { isLogged, isLoadStorage, isBanned, login } = context as any;

  const routes: RouteObject[] = [
    {
      element: <App />,
      children: [
        {
          path: '/',
          element: (
            <MainLayoutProvider>
              <MainLayout isLogged={isLogged as any} />
            </MainLayoutProvider>
          ),
          children: [
            {
              path: PATHS.LOGIN,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedLoginGuard isLogged={isLogged as any}>
                    <Login login={login} />
                  </LoggedLoginGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.HOME,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <Home />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.STATES,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <StateList />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.STATE_CREATE,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <StateForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },

            {
              path: PATHS.STATE_UPDATE,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <StateForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.ISSUES,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <IssueList />
                  </LoggedGuard>
                </BannedGuard>
              )
            },

            {
              path: PATHS.ISSUE_CREATE,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <IssueForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },

            {
              path: PATHS.ISSUE_UPDATE,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <IssueForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.LABELS,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <LabelList />
                  </LoggedGuard>
                </BannedGuard>
              )
            },

            {
              path: PATHS.LABEL_CREATE,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <LabelForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },

            {
              path: PATHS.LABEL_UPDATE,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <LabelForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.USERS,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <UserList />
                  </LoggedGuard>
                </BannedGuard>
              )
            },

            {
              path: PATHS.USER_CREATE,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <UserForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },

            {
              path: PATHS.USER_UPDATE,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <UserForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.NOTIFICATIONS,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <NotificationList />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.CALENDAR,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <Calendar />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.CONFIGURACION_EMAIL,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <EmailForm />
                  </LoggedGuard>
                </BannedGuard>
              )
            },

            {
              path: PATHS.LOGS_EMAIL,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <EmailLogsList />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.LOGS_ACCESS,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <AccessLogsList />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.ERROR_404,
              element: (
                <BannedGuard isBanned={isBanned}>
                  <LoggedGuard isLogged={isLogged as any}>
                    <Error404 />
                  </LoggedGuard>
                </BannedGuard>
              )
            },
            {
              path: PATHS.ERROR_500,
              element: (
                // <BannedGuard isBanned={!isBanned}>
                <LoggedLoginGuard isLogged={isLogged as any}>
                  <Error500 />
                </LoggedLoginGuard>
                // </BannedGuard>
              )
            },
            {
              path: '/',
              element: <Navigate to={PATHS.HOME} />
            },
            {
              path: '*',
              element: <Navigate to={PATHS.ERROR_404} />
            }
          ]
        }
      ]
    }
  ];

  const router = createBrowserRouter(routes);

  return !isLoadStorage ? (
    <RouterProvider router={router} />
  ) : (
    <Spinner top={'50vh'} left={'50%'} />
  );

  // return (
  //   <Suspense fallback={<Spinner top={'50vh'} left={'50%'} />}>
  //     {!isLoadStorage ? (
  //       <RouterProvider router={router} />
  //     ) : (
  //       <Spinner top={'50vh'} left={'50%'} />
  //     )}
  //   </Suspense>
  // );
};

import './Spinner.css';

export const Spinner = ({
  top = '45%',
  left = '50%'
}: {
  top?: string;
  left?: string;
}) => {
  return (
    <div
      className="loadingspinner"
      style={{ position: 'absolute', top: top, left: left }}
    ></div>
  );
};

import classNames from 'classnames';
import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import { PATHS } from 'core/constants/paths.constants';
import { useAppContext } from 'providers/AppProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { Suspense } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import { Spinner } from 'ui/spinner/Spinner';

interface MainLayoutProps {
  isLogged: boolean;
}

const MainLayout = ({ isLogged }: MainLayoutProps) => {
  const location = useLocation();
  const {
    config: { navbarPosition }
  } = useAppContext();

  const { contentClass, footerClass } = useMainLayoutContext();
  const excludesUrl = [PATHS.ERROR_500].includes(location.pathname);

  return (
    <Container fluid className="px-0">
      {isLogged && !excludesUrl ? (
        <>
          {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
            <NavbarVertical />
          )}
          {navbarPosition === 'vertical' && <NavbarTopDefault />}
          {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
            <NavbarTopHorizontal />
          )}
          {navbarPosition === 'dual' && <NavbarDual />}
        </>
      ) : (
        <></>
      )}

      <div className={classNames(contentClass, 'content')}>
        <Suspense fallback={<Spinner top={'50vh'} left={'50%'} />}>
          <Outlet />
        </Suspense>
        {isLogged && !excludesUrl ? (
          <Footer className={classNames(footerClass, 'position-absolute')} />
        ) : (
          <></>
        )}
        {/* <ChatWidget /> */}
      </div>
    </Container>
  );
};

export default MainLayout;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { callService } from 'core/functions/fetch.functions';
import { BASE_URL } from '../../../core/constants/common.constants';
import { User, UserPick } from '../models/user.models';

export class UserService {
  private static instance: UserService;
  private static enpointUrl: string = BASE_URL + '/api/controllers/user';
  private static endpoints = {
    getById: (id: string) => '/user-get.php?id=' + id,
    list: '/user-list.php',
    create: '/user-create.php',
    update: '/user-update.php',
    delete: '/user-delete.php'
  };

  // private constructor() {}

  public static getInstance(): UserService {
    if (!this.instance) {
      this.instance = new UserService();
    }
    return this.instance;
  }

  public static get(reload: boolean = false): Promise<User[]> {
    return this._getEndpointInfo(this.enpointUrl + this.endpoints.list);
  }

  public static getById(id: number): Promise<User | null | undefined> {
    return this._getEndpointInfo(
      this.enpointUrl + this.endpoints.getById(id?.toString()),
      {}
    );
  }

  public static save(user: Pick<User, UserPick>): Promise<number> {
    const body = this._getFormData(user);

    return callService(this.enpointUrl + this.endpoints.create, {
      method: 'POST',
      body
    })
      .then(id => id)
      .catch(error => {
        throw error;
      });
  }

  public static update(
    id: number,
    user: Pick<User, UserPick>
  ): Promise<number> {
    const body = this._getFormData(user, id);

    return callService(this.enpointUrl + this.endpoints.update, {
      method: 'POST',
      body
    })
      .then(() => id)
      .catch(error => {
        throw error;
      });
  }

  public static delete(id: number): Promise<number> {
    const body = new FormData();
    body.append('id', id?.toString());

    return callService(this.enpointUrl + this.endpoints.delete, {
      method: 'POST',
      body
    })
      .then(() => id)
      .catch(error => {
        throw error;
      });
  }

  private static _getEndpointInfo<T>(
    endpoint: string,
    defaultValue: any = []
  ): Promise<T> {
    return callService(endpoint, { method: 'GET' })
      .then(data => data ?? defaultValue)
      .catch(error => {
        throw error;
      });
  }

  private static _getFormData(
    user: Pick<User, UserPick>,
    id?: number
  ): FormData {
    const {
      user_login,
      user_pass,
      user_nombre,
      user_tipo,
      email,
      dni,
      direccion,
      ciudad,
      provincia,
      cp,
      telefono,
      movil,
      foto
    } = user ?? {};

    const body = new FormData();
    body.append('user_login', user_login);
    body.append('user_pass', user_pass);
    body.append('user_nombre', user_nombre?.toString());
    body.append('user_tipo', user_tipo?.toString());
    body.append('email', email);
    body.append('dni', dni);
    body.append('direccion', direccion);
    body.append('ciudad', ciudad);
    body.append('provincia', provincia);
    body.append('cp', cp);
    body.append('telefono', telefono);
    body.append('movil', movil);
    body.append('foto', foto);
    if (id) body.append('id', id?.toString());

    return body;
  }
}

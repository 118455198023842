/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { callService } from 'core/functions/fetch.functions';
import { BASE_URL } from '../../../core/constants/common.constants';
import { Credentials } from '../models/credentials.model';
import { LoggedUser, SessionLog } from '../models/logged-user.model';

export class LoginService {
  private static instance: LoginService;
  private static loggedUser: LoggedUser | null = null;
  private static enpointUrl: string = BASE_URL + '/api/controllers/login';
  private static endpoints = {
    logList: '/login-log-list.php',
    login: '/login.php',
    logout: '/logout.php',
    banned: '/check-banned.php'
  };

  // private constructor() {}

  public static getInstance(): LoginService {
    if (!this.instance) {
      this.instance = new LoginService();
    }
    return this.instance;
  }

  public static getLoggedUsers(): LoggedUser | null {
    return this.loggedUser ?? null;
  }

  public static login(credentials: Credentials): Promise<void> {
    const { user, password, isRefresh } = credentials ?? {};

    const body = new FormData();
    body.append('user', user);
    body.append('password', password);
    body.append('isRefresh', (isRefresh ?? false)?.toString());

    return fetch(this.enpointUrl + this.endpoints.login, {
      method: 'post',
      body,
      credentials: 'include'
    })
      .then(r => r?.json())
      .then(response => {
        const [element] = response ?? [];
        const { user, unauthorized } = element ?? {};

        if (Object.keys(unauthorized ?? {}).length) {
          throw 500;
        }

        if (!Object.keys(user ?? {}).length) {
          throw 'Clave incorrecta';
        }

        this.loggedUser = { ...(user ?? {}), ...credentials };
      })
      .catch(error => {
        throw error;
      });
  }

  public static getLogs(reload: boolean = false): Promise<SessionLog[]> {
    return this._getEndpointInfo();
  }

  private static _getEndpointInfo(): Promise<SessionLog[]> {
    return callService(this.enpointUrl + this.endpoints.logList, {
      method: 'GET'
    })
      .then(data => data ?? [])
      .catch(error => {
        throw error;
      });
  }

  public static checkBanned(): Promise<any> {
    this.loggedUser = null;
    return fetch(this.enpointUrl + this.endpoints.banned)
      .then(r => r?.json())
      .then(response => {
        const [element] = response ?? [];
        const { unauthorized } = element ?? {};
        return !!Object.keys(unauthorized ?? {}).length;
      })
      .catch(error => {
        throw error;
      });
  }

  public static logout(): Promise<any> {
    this.loggedUser = null;
    return fetch(this.enpointUrl + this.endpoints.logout).catch(() => null);
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import { AVATAR_IMG, IMAGE_URL } from 'core/constants/common.constants';
import { sliceText } from 'core/functions/common.functions';
import { Notification } from 'features/notification/models/notification.models';
import { Badge } from 'primereact/badge';

export interface NotificationItemProps<T> {
  notification: T;
  goToNotification: (item: T) => void;
  className?: string;
  type: 'dropdownItem' | 'pageItem';
}

const NotificationItem = <T,>({
  notification,
  goToNotification,
  className,
  type
}: NotificationItemProps<T>) => {
  return (
    <div
      onClick={() => goToNotification(notification as any)}
      style={{ cursor: 'pointer' }}
      className={classNames(
        className,
        'py-3 notification-card position-relative',
        {
          unread: !(notification as Notification).leida,
          'px-4 px-lg-6': type === 'pageItem',
          'px-2 px-sm-3': type === 'dropdownItem'
        }
      )}
    >
      <div className="d-flex align-items-center justify-content-between position-relative">
        <div className="d-flex">
          <Avatar
            src={
              (notification as Notification)?.foto
                ? IMAGE_URL((notification as Notification)?.foto as string)
                : AVATAR_IMG
            }
            placeholder={!(notification as Notification)?.foto}
            size={type === 'pageItem' ? 'xl' : 'xl'}
            className="me-3 position-relative"
            // status-online
          />

          {!(notification as Notification)?.leida ? (
            <Badge
              style={{
                position: 'absolute',
                top: '0px',
                left: '35px',
                width: '1rem',
                height: '1rem'
              }}
              severity="danger"
            ></Badge>
          ) : (
            <></>
          )}
          <div
            className={classNames('flex-1', {
              'me-sm-3': type === 'dropdownItem',
              'mt-2 me-2': type === 'pageItem'
            })}
          >
            <h4 className="fs-9 text-body-emphasis">
              {(notification as Notification)?.nombreUser ?? '-'}
            </h4>
            <p className="fs-9 text-body-highlight mb-2 mb-sm-3 fw-normal">
              {/* <span className="me-1 fw-bold fs-10">
                {notification.interactionIcon}
              </span> */}
              {/* <span>{notification.interaction}</span> */}
              {/* {type === 'pageItem' && (
                <span className="fw-bold">
                  {(notification as Notification)?.mensaje ?? '-'}
                </span>
              )} */}
              <span className="fw-bold">
                {sliceText((notification as Notification)?.titulo ?? '-')}
              </span>
              <br></br>
              <span className="text-body-quaternary text-opactity-75 fw-bold fs-10">
                {sliceText((notification as Notification)?.mensaje ?? '-', 40)}
              </span>
            </p>
            <p className="text-body-secondary fs-9 mb-0">
              <FontAwesomeIcon icon={faClock} className="me-1" />
              <span className="fw-bold">
                {(notification as Notification)?.fecha_creacion ?? '-'}
              </span>
            </p>
          </div>
        </div>
        {/* {!(notification as Notification)?.leida ? (
          <Badge
            style={{
              fontSize: '0.5rem',
              position: 'absolute',
              top: '10px',
              left: '20px'
            }}
            bg="danger"
            text="dark"
          ></Badge>
        ) : (
          <></>
        )} */}
        {/* <RevealDropdown
          className={classNames('', {
            'd-none d-sm-block': type === 'dropdownItem'
          })}
          btnClassName="notification-dropdown-toggle"
          dropdownMenuClassName="mt-2"
        >
          <Dropdown.Item>
            Mark as {notification.read ? 'unread' : 'read'}
          </Dropdown.Item>
        </RevealDropdown> */}
      </div>
    </div>
  );
};

export default NotificationItem;

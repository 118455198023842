interface NoDataProps {
  message?: string;
  marginTop?: string;
  color?: string;
}

export const NoData = ({
  message = 'No hay ningun contenido',
  marginTop = '50px',
  color = 'white'
}: NoDataProps) => {
  return (
    <div
      style={{ width: '100%', textAlign: 'center', marginTop, color: color }}
    >
      {message ?? ''}
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { callService } from 'core/functions/fetch.functions';
import { BASE_URL } from '../../../core/constants/common.constants';
import { Notification, NotificationPick } from '../models/notification.models';

export class NotificationService {
  private static instance: NotificationService;
  private static enpointUrl: string =
    BASE_URL + '/api/controllers/notification';
  private static endpoints = {
    list: '/notification-list.php',
    listByLoggged: '/notification-list-by-logged.php',
    checkRead: '/notification-ckeck-read.php',
    create: '/notification-create.php',
    update: '/notification-update.php',
    delete: '/notification-delete.php'
  };

  // private constructor() {}

  public static getInstance(): NotificationService {
    if (!this.instance) {
      this.instance = new NotificationService();
    }
    return this.instance;
  }

  public static get(reload: boolean = false): Promise<Notification[]> {
    return this._getEndpointInfo();
  }

  public static getByLogged(reload: boolean = false): Promise<Notification[]> {
    return this._getEndpointInfoByLogged();
  }

  public static getById(id: number): Promise<Notification | null | undefined> {
    return this.get(true)
      .then(notifications => notifications?.find(item => item?.id === +id))
      .catch(() => null);
  }

  public static save(
    notification: Pick<Notification, NotificationPick>
  ): Promise<number> {
    const body = this._getFormData(notification);

    return callService(this.enpointUrl + this.endpoints.create, {
      method: 'POST',
      body
    })
      .then(id => id)
      .catch(error => {
        throw error;
      });
  }

  public static update(
    id: number,
    notification: Pick<Notification, NotificationPick>
  ): Promise<number> {
    const body = this._getFormData(notification, id);

    return callService(this.enpointUrl + this.endpoints.update, {
      method: 'POST',
      body
    })
      .then(() => id)
      .catch(error => {
        throw error;
      });
  }

  public static delete(id: number): Promise<number> {
    const body = new FormData();
    body.append('id', id?.toString());

    return callService(this.enpointUrl + this.endpoints.delete, {
      method: 'POST',
      body
    })
      .then(() => id)
      .catch(error => {
        throw error;
      });
  }

  public static checkRead(id: number): Promise<Notification[]> {
    const body = this._getFormDataCheckRead(id);

    return callService(this.enpointUrl + this.endpoints.checkRead, {
      method: 'POST',
      body
    })
      .then(() => this.getByLogged(true))
      .catch(error => {
        throw error;
      });
  }

  private static _getEndpointInfo(): Promise<Notification[]> {
    return callService(this.enpointUrl + this.endpoints.list, { method: 'GET' })
      .then(data => data ?? [])
      .catch(error => {
        throw error;
      });
  }

  private static _getEndpointInfoByLogged(): Promise<Notification[]> {
    return callService(this.enpointUrl + this.endpoints.listByLoggged, {
      method: 'GET'
    })
      .then(data => data ?? [])
      .catch(error => {
        throw error;
      });
  }

  private static _getFormData(
    notification: Pick<Notification, NotificationPick>,
    id?: number
  ): FormData {
    const { titulo, mensaje, id_user, fecha } = notification ?? {};

    const body = new FormData();
    body.append('titulo', titulo);
    body.append('mensaje', mensaje);
    body.append('id_user', id_user?.toString());
    body.append('fecha', fecha);
    if (id) body.append('id', id?.toString());

    return body;
  }

  private static _getFormDataCheckRead(id: number): FormData {
    const body = new FormData();
    body.append('id', id?.toString());
    return body;
  }
}
